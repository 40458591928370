<template>
  <div class="forget-password-container">
    <!-- 背景视频 -->
    <video
      :style="fixStyle"
      class="video-box"
      autoplay
      loop
      muted
    >
      <source
        src="../assets/video/inti.mp4"
        type="video/mp4"
      />
    </video>

    <!-- 语言切换 -->
    <div class="language-box">
      <el-select
        v-model="lang.value"
        popper-class="public-select-dropdown"
        @change="langChange"
      >
        <el-option
          v-for="item in lang.options"
          :key="item.value"
          :label="item.label"
          :value="item.value"
        ></el-option>
      </el-select>
    </div>

    <!-- 内容区域 -->
    <div class="content-box">
      <!-- logo、商会名称 -->
      <div class="chamber-name-box">
        <div class="chamber-left">
          <img src="@/assets/imgs/Logo_INTI.jpg" />
        </div>
        <div class="chamber-right">
          <p>{{ $t('Title1') }}</p>
          <p>{{ $t('Title2') }}</p>
        </div>
      </div>

      <!-- 返回登录页面 -->
      <p
        class="go-login"
        @click="$router.push({ name: 'login' })"
      >
        <i class="iconfont icon-fanhui"></i>
        <span>{{ $t('word1005') }}</span>
      </p>

      <!-- 表单区域 -->
      <div class="form-box">
        <el-form
          :model="form"
          :rules="formRules"
          ref="formRef"
        >
          <!-- 验证码（4位数） -->
          <el-form-item
            prop="verifyCode"
            class="verify-code-item"
            :error="verifyCodeError"
          >
            <el-input
              v-model="form.verifyCode"
              prefix-icon="iconfont icon-yanzhengyanzhengma left-icon"
              :placeholder="$t('word1016')"
              maxlength="4"
              @input="form.verifyCode = form.verifyCode.replace(/\D/g, '')"
            >
            </el-input>

            <div
              class="verify-code-box"
              @click="getVerifyCode4"
            >
              <SIdentify :identifyCode="identifyCode"></SIdentify>
            </div>
          </el-form-item>
          <!-- 邮箱 -->
          <el-form-item
            prop="phone"
            class="phone-item"
            :error="phoneError"
          >
            <el-input
              v-model="form.phone"
              prefix-icon="iconfont icon-youxiang left-icon"
              :placeholder="$t('word1002')"
              @input="form.phone = form.phone.replace(/[^\a-\z\A-\Z0-9\_\-\@\.]/g, '')"
            >

              <el-button
                slot="append"
                class="verify-code"
                :disabled="btnConfig.disabled"
                :loading="loading"
                @click="getVerifyCode"
              >{{ btnConfig.title }}</el-button>
            </el-input>
          </el-form-item>
          <!-- 验证码（6位数） -->
          <el-form-item
            prop="code"
            class="code-item"
          >
            <el-input
              v-model="form.code"
              prefix-icon="iconfont icon-yanzhengma left-icon"
              :placeholder="$t('word1027')"
              maxlength="6"
              @input="form.code = form.code.replace(/\D/g, '')"
            >
            </el-input>
          </el-form-item>
          <!-- 新密码 -->
          <el-form-item
            prop="password"
            class="password-item"
          >
            <el-input
              v-model.trim="form.password"
              prefix-icon="iconfont icon-mima left-icon"
              :placeholder="$t('word1029')"
              show-password
              maxlength="16"
              @input="form.password = form.password.replace(/[^\a-\z\A-\Z0-9\!\@\#\$\%\^\&\*\_]/g, '')"
            >
            </el-input>
          </el-form-item>
          <!-- 重复 -->
          <el-form-item
            prop="passwordRepeat"
            class="password-repeat-item"
          >
            <el-input
              v-model.trim="form.passwordRepeat"
              prefix-icon="iconfont icon-repeat left-icon"
              :placeholder="$t('word1018')"
              show-password
              maxlength="16"
              @input="form.passwordRepeat = form.passwordRepeat.replace(/[^\a-\z\A-\Z0-9\!\@\#\$\%\^\&\*\_]/g, '')"
            >
            </el-input>
          </el-form-item>
          <!-- 按钮 -->
          <el-form-item class="btn-item">
            <el-button
              class="btn-box"
              @click="updatePassword"
            >{{ $t('word1030') }}</el-button>
          </el-form-item>
        </el-form>
      </div>
    </div>
  </div>
</template>

<script>
import mixin from '@/mixins/mixin.js'
import SIdentify from '@/components/SIdentify.vue'
import { getVerifyCode4, getVerifyCode6, updatePassword } from '@/api/api.js'

export default {
  name: 'ForgetPassword',
  mixins: [mixin],
  components: {
    SIdentify
  },
  data() {
    // 检查邮箱
    const checkPhone = (rule, value, callback) => {
      const regPhone = /^[a-zA-Z0-9]+([.-_]*[a-zA-Z0-9]+)*@([a-zA-Z0-9]+[-_]*[a-zA-Z0-9]+.)+[a-zA-Z0-9]{2,6}$/
      if (!value) {
        callback(new Error(this.$t('word1023')))
      } else if (value && !regPhone.test(value)) {
        callback(new Error(this.$t('word1063')))
      } else {
        callback()
      }
    }
    // 检查验证码
    const checkVerifyCode = (rule, value, callback) => {
      if (!value) {
        callback(new Error(this.$t('word1019')))
      } else if (value !== this.identifyCode) {
        callback(new Error(this.$t('word1020')))
      } else {
        callback()
      }
    }
    // 检查密码是否一致
    const checkPassword = (rule, value, callback) => {
      if (this.form.password === this.form.passwordRepeat) callback()
      else callback(new Error(this.$t('word1021')))
    }

    return {
      fixStyle: '',
      // 语言数据
      lang: {
        options: [
          {
            value: 'zh-CN',
            label: '简体中文'
          },
          {
            value: 'en-US',
            label: 'English'
          },
          {
            value: 'bahasa-Indonesia',
            label: 'Bahasa Indonesia'
          },
          /* {
            value: 'Malay',
            label: 'Bahasa Melayu'
          },
          {
            value: 'Thai',
            label: 'ภาษาไทย'
          },

          {
            value: 'Vietnamese',
            label: 'Tiếng Việt'
          },
          {
            value: 'Burmese',
            label: 'ဗမာစာ'
          },
          {
            value: 'Lao',
            label: 'ພາສາລາວ'
          },
          {
            value: 'Khmer',
            label: 'ភាសាខ្មែរ'
          },
          {
            value: 'Filipino',
            label: 'Wikang Filipino'
          },
          {
            value: 'Portuguese',
            label: 'Português'
          }, */
        ],
        value: sessionStorage.getItem('locale') ? sessionStorage.getItem('locale') : 'zh-CN'
      },
      // 表单对象
      form: {
        verifyCode: '', // 验证码（4位数）
        phone: '',
        code: '', // 短信验证码（6位数）
        password: '',
        passwordRepeat: ''
      },
      // 表单校验对象
      formRules: {
        phone: [{ required: true, validator: checkPhone, trigger: 'blur' }],
        verifyCode: [{ validator: checkVerifyCode, required: true, trigger: 'blur' }],
        code: [{ required: true, message: this.$t('word1028'), trigger: 'blur' }],
        password: [
          { required: true, message: this.$t('word1031'), trigger: 'blur' },
          { min: 8, max: 16, message: this.$t('word1062'), trigger: 'blur' }
        ],
        passwordRepeat: [
          { required: true, message: this.$t('word1024'), trigger: 'blur' },
          { min: 8, max: 16, message: this.$t('word1062'), trigger: 'blur' },
          { validator: checkPassword, trigger: 'blur' }
        ]
      },
      verifyCodeError: '', // 验证码插件错误提示
      phoneError: '', // 手机号错误提示
      // 按钮配置
      btnConfig: {
        title: this.$t('word1017'),
        disabled: false
      },
      // 验证码插件
      identifyCode: '',
      loading: false
    }
  },
  created() {
    this.getVerifyCode4()
  },
  mounted() {
    this.synchronizeWindowSize()
  },
  methods: {
    // 获取验证码（4位数）
    getVerifyCode4() {
      getVerifyCode4({}).then(res => {
        if (res.code === 200) {
          this.identifyCode = res.msg
        }
      })
    },

    // 主要是检测窗口缩放视频也同步大小
    synchronizeWindowSize() {
      window.onresize = () => {
        const windowWidth = document.body.clientWidth
        const windowHeight = document.body.clientHeight
        const windowAspectRatio = windowHeight / windowWidth
        let videoWidth
        let videoHeight
        if (windowAspectRatio < 0.5625) {
          videoWidth = windowWidth
          videoHeight = videoWidth * 0.5625
          this.fixStyle = {
            height: windowWidth * 0.5625 + 'px',
            width: windowWidth + 'px',
            'margin-bottom': (windowHeight - videoHeight) / 2 + 'px',
            'margin-left': 'initial'
          }
        } else {
          videoHeight = windowHeight
          videoWidth = videoHeight / 0.5625
          this.fixStyle = {
            height: windowHeight + 'px',
            width: windowHeight / 0.5625 + 'px',
            'margin-left': (windowWidth - videoWidth) / 2 + 'px',
            'margin-bottom': 'initial'
          }
        }
      }
      window.onresize()
    },

    // 获取验证码
    getVerifyCode() {
      const regPhone = /^[a-zA-Z0-9]+([.-_]*[a-zA-Z0-9]+)*@([a-zA-Z0-9]+[-_]*[a-zA-Z0-9]+.)+[a-zA-Z0-9]{2,6}$/
      // 判断验证码是否填写、或填写正确
      if (!this.form.verifyCode) return (this.verifyCodeError = this.$t('word1019'))
      if (this.form.verifyCode !== this.identifyCode) return (this.verifyCodeError = this.$t('word1020'))

      if (!this.form.phone) return (this.phoneError = this.$t('word1023'))
      if (this.form.phone && !regPhone.test(this.form.phone)) return (this.phoneError = this.$t('word1063'))

      this.loading = true
      getVerifyCode6({
        useFor: '1', // '0': 注册、'1': 忘记密码
        areaCode: '86',
        securityCode: this.form.verifyCode,
        mobile: this.form.phone,
        userType: 0 // 0:商会会员、1:律所会员、2:律所律师、3:商会/律所后台
      }).then(res => {
        this.loading = false
        if (res.code === 200) {
          this.verifyCodeError = ''
          this.phoneError = ''
          this.btnConfig.disabled = true
          this.countDown()
        } else if (res.code === 503) {
          this.$message.error({ message: this.$t('AccountInexistence') })
        }
      })
    },

    // 倒计时
    countDown() {
      let time = 60
      const timer = setInterval(() => {
        if (time === 0) {
          clearInterval(timer)
          this.btnConfig.title = this.$t('Resend')
          this.btnConfig.disabled = false
        } else {
          this.btnConfig.title = `${time}${this.$t('word1025')}`
          this.btnConfig.disabled = true
          time--
        }
      }, 1000)
    },

    // 修改密码
    updatePassword() {
      this.$refs.formRef.validate(valid => {
        if (valid) {
          const { phone, code, password } = this.form
          const params = {
            areaCode: '86',
            mobile: phone,
            verifyCode: code,
            password
          }
          updatePassword(params).then(res => {
            if (res.code === 600534) {
              // 邮箱验证码过期或未发送
              this.$message.error({ message: this.$t('word1064'), duration: 3000 })
            } else if (res.code === 600532) {
              // 邮箱验证码错误
              this.$message.error({ message: this.$t('word1065'), duration: 2000 })
            } else {
              this.$message.success({ message: this.$t('word1032'), duration: 1500 })
              this.$router.push({ name: 'login' })
            }
          })
        } else {
          return false
        }
      })
    },

    // 语言切换
    langChange(value) {
      const lang = value === 'zh-CN' ? 'zh_cn' : value === 'en-US' ? 'en' : value
      this.setCookie('org.springframework.web.servlet.i18n.CookieLocaleResolver.LOCALE', lang)
      sessionStorage.setItem('locale', value)
      sessionStorage.setItem('changeChange', 'yes')
      window.location.reload() // 刷新页面
    }
  }
}
</script>

<style lang="scss" scoped>
.forget-password-container {
  position: relative;
  height: 100%;
  overflow: hidden;

  // 背景视频
  .video-box {
    position: absolute;
    z-index: 0;
  }

  // 语言切换
  .language-box {
    position: absolute;
    top: 40px;
    left: 80px;
    z-index: 2;
    width: 160px;
    ::v-deep .el-select {
      .el-input__inner {
        background-color: rgba(255, 255, 255, 0.8);
        color: #43403d;
      }
      .el-select__caret {
        color: #43403d;
      }
    }
  }

  // 内容区域
  .content-box {
    position: absolute;
    left: 80px;
    z-index: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 100%;

    // logo、商会名称
    .chamber-name-box {
      display: flex;
      align-items: center;
      width: 700px;
      margin-bottom: 30px;
      & > .chamber-left {
        width: 100px;
        height: 100px;
        margin-right: 10px;
        & > img {
          display: block;
          width: 100%;
          height: 100%;
        }
      }
      & > .chamber-right {
        flex: 1;
        width: 0;
        flex-wrap: wrap;
        color: #ffffff;
        font-size: 20px;
        & > p:first-child {
          margin-bottom: 10px;
        }
      }
    }

    // 返回登录页面
    .go-login {
      display: inline-block;
      margin-bottom: 30px;
      color: #ffffff;
      font-size: 18px;
      cursor: pointer;

      .iconfont {
        margin-right: 5px;
        font-size: 18px;
      }
    }

    // 表单区域
    .form-box {
      width: 460px;
      height: 400px;
      padding-right: 10px;
      overflow-x: hidden;
      overflow-y: auto;

      // 滚动条样式
      &::-webkit-scrollbar {
        width: 4px;
        height: 4px;
      }
      &::-webkit-scrollbar-thumb {
        background-color: rgba(255, 255, 255, 0.8);
        border-radius: 5px;
      }
      &::-webkit-scrollbar-track {
        background-color: transparent;
      }

      ::v-deep .el-form {
        .el-form-item {
          margin-bottom: 30px;
        }
        .el-input__inner {
          background-color: transparent;
          color: #ffffff;
          border: none;
          border-bottom: 1px solid #ffffff;
          border-radius: 0;
        }
        .el-input--prefix .el-input__inner {
          padding-left: 40px;
        }
        .left-icon {
          color: #ffffff;
          font-size: 24px;
        }
        // 验证码插件
        .verify-code-item {
          .el-form-item__content {
            display: flex;
          }
          .el-input__inner {
            text-overflow: ellipsis;
          }
        }
        // 邮箱
        .phone-item {
          .el-input-group__append {
            background-color: rgba(255, 255, 255, 0.8);
            padding: 0 10px;
            color: #43403d;
            border: none;
          }
        }
        // 按钮区域
        .btn-item {
          margin-bottom: 0;
          .el-form-item__content {
            text-align: center;
          }
          .btn-box {
            background-color: rgba(255, 255, 255, 0.8);
            padding: 12px 40px;
            color: #43403d;
          }
        }
      }
    }
  }
}
</style>